<template>
    <div>
        <b-card class="">
            <b-card-header class="p-0">
                <div class="row">
                    <div class="col-md-6 form-group align-self-center">
                        <b-card-title class="mb-0">{{ $t("MENU.ITEMS")}}</b-card-title>
                    </div>
                    <div class="col-md-6 form-group">
                        <router-link :to="{name: 'items_add'}">
                            <b-button class="float-right" variant="primary"><i class="flaticon2-plus icon-sm"></i> {{ $t("GENERAL.ADD_ITEM")}}</b-button>
                        </router-link>
                    </div>
                </div>
            </b-card-header>
            <b-form @submit="onSubmit">
                <b-card-body>
                    <b-row>
                        <b-col md>
                            <b-form-group>
                                <b-form-input v-model="form.searchTerm"
                                              type="text"
                                              :placeholder="$t('GENERAL.ENTER_NAME_OR_CODE')"></b-form-input>
                            </b-form-group>

                        </b-col>
                        <b-col md>
                            <b-form-group>
                                <b-form-select v-model="form.categoryId"
                                               :options="categories"
                                               @change="selectCategory"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md>
                            <b-form-group>
                                <b-form-select v-model="form.subcategoryId"
                                               :options="subcategories"
                                               :disabled="toggleDisabledSubcategoriesSelect"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md>
                            <b-form-group>
                                <b-button type="submit" variant="primary"><i class="flaticon2-search"></i> {{ $t("GENERAL.SEARCH") }}</b-button>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md>
                            <hr />
                            <b-col sm="2" md="2" class="pl-0 pr-0">
                                <b-form-group>
                                    <b-form-select class="col-md-6" 
                                                   v-model="pagination.perPage"
                                                   :options="[10,25,50,75,100]"></b-form-select>
                                    <b-form-text>{{ $t("GENERAL.RESULTS_PER_PAGE")}}</b-form-text>
                                </b-form-group>
                            </b-col>
                            <b-table id="paged-table"
                                     head-variant="light"
                                     hover
                                     responsive
                                     :per-page="pagination.perPage"
                                     :current-page="pagination.currentPage"
                                     :items="items"
                                     :fields="fields">

                                <template #cell(isActive)="data" class="text-center">
                                    <b-form-checkbox disabled size="lg" v-model="data.value"></b-form-checkbox>
                                </template>

                                <template #cell(isFeatured)="data" class="text-center">
                                    <b-form-checkbox disabled size="lg" v-model="data.value"></b-form-checkbox>
                                </template>

                                <template #cell(actions)="data">
                                    <b-button size="sm"
                                              @click="remove(data.item.id)"
                                              variant="outline-danger text-center float-right"
                                              :title="$t('GENERAL.DELETE')">
                                        <i class="flaticon-delete icon-md p-0"></i>
                                    </b-button>
                                    <router-link :to="{name: 'items_edit', params: {id: data.item.id}}">
                                        <b-button size="sm" class="mr-1"
                                                  variant="outline-primary text-center float-right"
                                                  :title="$t('GENERAL.EDIT')">
                                            <i class="flaticon-edit icon-md p-0"></i>
                                        </b-button>
                                    </router-link>
                                </template>
                            </b-table>
                            <hr />
                            <b-pagination v-model="pagination.currentPage"
                                          aria-controls="paged-table"
                                          :total-rows="pagination.totalRecords"
                                          :per-page="pagination.perPage"
                                          align="center"
                                          :hide-goto-end-buttons="!pagination.firstAndLastButton"
                                          :next-text="$t('GENERAL.NEXT_SHORT')"
                                          :prev-text="$t('GENERAL.PREVIOUS_SHORT')"></b-pagination>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-form>
        </b-card>
    </div>
</template>

<script>

    import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
    import { GET_ITEMS, DELETE_ITEMS } from "@/core/services/store/items.module";
    import { GET_CATEGORIES } from "@/core/services/store/categories.module";
    import { GET_SUBCATEGORIES } from "@/core/services/store/subcategories.module";
    import { mapState } from "vuex";

    export default {
        name: "index",
        components: {
        },
        data() {
            return {
                form: {
                    searchTerm: null,
                    categoryId: null,
                    subcategoryId: null,
                    isActive: true
                },
                pagination: {
                    currentPage: 1,
                    perPage: 10,
                    totalRecords: 0,
                    firstAndLastButton: false,
                }

            }
        },
        computed: {
            toggleDisabledSubcategoriesSelect() {
                return this.form.categoryId == null;
            },
            fields() {
                return [
                    { key: "code", label: this.$t("GENERAL.CODE"), sortable: true },
                    { key: "name", label: this.$t("GENERAL.NAME"), sortable: true },
                    { key: "isActive", label: this.$t("GENERAL.IS_ACTIVE"), sortable: true, class: "text-center" },
                    { key: "isFeatured", label: this.$t("GENERAL.IS_FEATURED"), sortable: true, class: "text-center" },
                    { key: "actions", label: "" }]
            },
            categories() {
                let cats = [{ value: null, text: this.$t("GENERAL.CHOOSE_CATEGORY") }];
                this.$store.getters.categories.sort((a, b) => { a.ordinalNumber - b.ordinalNumber }).forEach(c => cats.push({ value: c.id, text: c.categoryTranslations[0].name }))
                return cats;
            },
            subcategories() {
                let subcats = [{ value: null, text: this.$t("GENERAL.CHOOSE_SUBCATEGORY") }];
                this.$store.getters.subcategories.sort((a, b) => { a.ordinalNumber - b.ordinalNumber }).forEach(c => subcats.push({ value: c.id, text: c.subcategoryTranslations[0].name }))
                return subcats;
            },
            items() {
                let itemRecords = [];
                this.$store.getters.items.sort((a, b) => { a.itemTranslations[0].name - b.itemTranslations[0].name }).forEach(c => itemRecords.push({ id: c.id, code: c.code, name: c.itemTranslations[0].name, isActive: c.isActive, isFeatured: c.storeItems[0].isFeatured }))
                this.pagination.totalRecords = itemRecords.length;
                return itemRecords;
            }
        },
        methods: {
            ...mapState({
                errors: state => state.items.errors
            }),
            onSubmit(evt) {
                evt.preventDefault()
                this.$store.dispatch(GET_ITEMS, this.form);
            },
            selectCategory() {
                if (this.form.categoryId != null) {
                    this.$store.dispatch(GET_SUBCATEGORIES, this.form.categoryId);
                }
                else {
                    this.form.subcategoryId = null;
                }
            },
            remove(id) {
                this.$store.dispatch(DELETE_ITEMS, id);
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.ITEMS") }]);
        },
        created() {
            this.$store.dispatch(GET_ITEMS, this.form);
            this.$store.dispatch(GET_CATEGORIES);
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
